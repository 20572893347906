import React from "react";
import { OrganisationInfoAppListViewModel } from "../../openapi/backend";
import LazyImage from "../LazyImage/LazyImage";
import Title from "../Title/Title";
import { ChevronRight } from "../../assets/images/Images";
import { t } from "i18next";
import styles from "./NearbyListCard.module.scss";
import { useAgeLabel } from "../../hooks/useAgeLabel";
const IMAGE_SIZE = 100;
const ARROW_SIZE = 22;
interface Props {
    organisation: OrganisationInfoAppListViewModel;
}
function NearbyListCard({ organisation }: Props) {
    const { title, images, minimumAge, category, maximumAge } = organisation;
    const ageLabel = useAgeLabel(minimumAge, maximumAge);
    return (
        <div className={styles.container}>
            <LazyImage
                width={IMAGE_SIZE}
                height={IMAGE_SIZE}
                threshold={268}
                alt={t("image.cover.alt", { name: title })}
                className={styles.organisationImage}
                src={images[0].href}
            />
            <div className={styles.organisationDetails}>
                <Title label={title} heading="h2" className={styles.title} />
                <div style={{ color: category.color }}>{category.name}</div>
                <div>{ageLabel}</div>
                <div className={styles.arrow}>
                    <img src={ChevronRight} alt={t("icon.right_arrow")} width={ARROW_SIZE} height={ARROW_SIZE} />
                </div>
            </div>
        </div>
    );
}

export default NearbyListCard;
