import React, { useCallback, useEffect, useMemo } from "react";
import FriendlyError from "../FriendlyError/FriendlyError";
import PerformanceMap from "./PerformanceMap";
import { useOrganisationFilters } from "../../hooks/useRouterHooks";
import { RequestState } from "../../types/RequestState";
import { useGetOrganisationsMapCallback } from "../../hooks/useOrganisationApiHooks";
import { Env } from "../../utils/Env";
import { APIProvider } from "@vis.gl/react-google-maps";

function NearbyMapContainer() {
    const [{ state, value, error }, fetchRequest] = useGetOrganisationsMapCallback();
    const [filters] = useOrganisationFilters();

    const isLoading = useMemo(() => state === RequestState.LOADING, [state]);
    const fetch = useCallback(() => fetchRequest({ ...filters }), [filters, fetchRequest]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    return (
        <FriendlyError state={state} error={error} retry={fetch}>
            <APIProvider apiKey={Env.REACT_APP_GOOGLE_MAP_API_KEY}>
                <PerformanceMap
                    loading={isLoading}
                    points={value}
                    mapId={Env.REACT_APP_GOOGLE_MAP_ID}
                    type="organisation"
                />
            </APIProvider>
        </FriendlyError>
    );
}

export default NearbyMapContainer;
