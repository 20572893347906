import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Map, AdvancedMarker, Pin, useMap } from "@vis.gl/react-google-maps";
import { MapItemProperties, MapItemViewModel } from "../../openapi/backend";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import type { Cluster, ClusterStats, Marker } from "@googlemaps/markerclusterer";
import Loading from "../Loading/Loading";
import styles from "./PerformanceMap.module.scss";
import useGeolocation from "../../hooks/useGeolocation";
import AvatarIcon from "../AvatarIcon/AvatarIcon";
import { strings } from "../../Localization/strings";
import { MARKER_COLOR, MarkerSvg, MAX_ZOOM } from "../../constants/MapConstants";
import { GeoViewport, getGeoViewportFromLatLngBoundLiteral, getGeoViewportFromPosition } from "../../utils/MapUtils";
import { lazyBeforeLoad } from "../../utils/Workarounds";
import HappeningListClusterContainer from "../HappeningList/HappeningListClusterContainer";
import NearbyListClusterContainer from "../NearbyList/NearbyListClusterContainer";

type DisplayType = "organisation" | "happening";
interface Props {
    points?: MapItemViewModel[] | undefined;
    loading: boolean;
    type: DisplayType;
    mapId: string;
}
interface Markers {
    properties: MapItemProperties;
}

const RoundMarker = ({ properties }: Markers) => {
    return <AvatarIcon size={36} name={properties.name} className={styles.RoundMarker} src={properties.imageHref} />;
};

const PerformanceMap = ({ loading, points, mapId, type }: Props) => {
    const coords = useGeolocation();
    const map = useMap();
    const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
    const [showList, setShowList] = useState<GeoViewport | undefined>(undefined);
    const hideList = useCallback(() => {
        if (showList) {
            setShowList(undefined);
        }
    }, [showList, setShowList]);

    const clusterRenderer = useMemo(
        () => ({
            render: (cluster: Cluster, stats: ClusterStats, map: google.maps.Map) => {
                const parser = new DOMParser();
                const svgEl = parser.parseFromString(
                    strings.formatString(MarkerSvg, cluster.count.toString()) as string,
                    "image/svg+xml",
                ).documentElement;

                return new google.maps.marker.AdvancedMarkerElement({
                    map,
                    position: cluster.position,
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count,
                    title: strings.formatString(strings.clusterText, cluster.count) as string,
                    content: svgEl,
                });
            },
        }),
        [],
    );
    const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
        setMarkers((markers) => {
            if ((marker && markers[key]) || (!marker && !markers[key])) return markers;
            if (marker) {
                return { ...markers, [key]: marker };
            } else {
                const newMarkers = { ...markers };
                delete newMarkers[key];
                return newMarkers;
            }
        });
    }, []);

    const onClusterClick = (event: google.maps.MapMouseEvent, cluster: Cluster, map: google.maps.Map) => {
        map.fitBounds(cluster.bounds as google.maps.LatLngBounds);
        if (map.getZoom() === MAX_ZOOM) {
            setShowList(getGeoViewportFromLatLngBoundLiteral(cluster.bounds));
        }
    };
    const clusterer = useMemo(() => {
        if (!map) return null;
        return new MarkerClusterer({
            map,
            renderer: clusterRenderer,
            algorithmOptions: { maxZoom: MAX_ZOOM },
            onClusterClick,
        });
    }, [map, clusterRenderer]);

    useEffect(() => {
        if (!clusterer) return;
        clusterer.clearMarkers();
        clusterer.addMarkers(Object.values(markers));
    }, [clusterer, markers]);

    useEffect(() => {
        map?.panTo({ lat: coords.latitude, lng: coords.longitude });
    }, [coords, map]);

    useEffect(() => {
        lazyBeforeLoad();
    }, []);

    const ClusterListComponent = useMemo(() => {
        switch (type) {
            case "organisation":
                return NearbyListClusterContainer;
            case "happening":
                return HappeningListClusterContainer;
        }
    }, [type]);

    const Markers = useMemo(
        () =>
            points && (
                <>
                    {points.map((location) => (
                        <AdvancedMarker
                            onClick={() => {
                                map?.moveCamera({
                                    center: {
                                        lat: location.geometry.coordinates[1],
                                        lng: location.geometry.coordinates[0],
                                    },
                                    zoom: MAX_ZOOM,
                                });
                                setShowList(
                                    getGeoViewportFromPosition(
                                        location.geometry.coordinates[1],
                                        location.geometry.coordinates[0],
                                    ),
                                );
                            }}
                            key={location.id}
                            position={{ lat: location.geometry.coordinates[1], lng: location.geometry.coordinates[0] }}
                            ref={(marker) => setMarkerRef(marker, location.id)}
                        >
                            <Pin background={MARKER_COLOR} borderColor={MARKER_COLOR}>
                                <RoundMarker properties={location.properties} />
                            </Pin>
                        </AdvancedMarker>
                    ))}
                </>
            ),
        [points, setMarkerRef, map],
    );
    if (loading) {
        return <Loading className={styles.loading} />;
    }
    return (
        <>
            <Map
                onClick={hideList}
                onZoomChanged={(event) => {
                    if (event.detail.zoom < MAX_ZOOM) {
                        hideList();
                    }
                }}
                mapId={mapId}
                streetViewControl={false}
                fullscreenControl={false}
                clickableIcons={false}
                defaultZoom={13}
                defaultCenter={{ lat: coords.latitude, lng: coords.longitude }}
            >
                {Markers}
                {showList && <ClusterListComponent viewport={showList} />}
            </Map>
        </>
    );
};
export default PerformanceMap;
