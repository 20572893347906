import React, { useCallback, useEffect } from "react";
import { useGetOrganisationsClusterListCallback } from "../../hooks/useOrganisationApiHooks";
import NearbyList from "./NearbyList";
import FriendlyError from "../FriendlyError/FriendlyError";
import { GeoViewport } from "../../utils/MapUtils";
interface Props {
    viewport: GeoViewport;
}
function NearbyListClusterContainer({ viewport }: Props) {
    const [{ value, state, error }, fetchRequest] = useGetOrganisationsClusterListCallback(viewport);
    const fetch = useCallback(() => fetchRequest({}), [fetchRequest]);
    useEffect(() => {
        fetch();
    }, [fetch]);
    return (
        <FriendlyError state={state} error={error} retry={fetch}>
            <NearbyList list={value?.items} />
        </FriendlyError>
    );
}

export default NearbyListClusterContainer;
