import React from "react";
import { OrganisationInfoAppListViewModel } from "../../openapi/backend";
import { hasValues } from "../../utils/Array";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import NearbyListCard from "../NearbyListCard/NearbyListCard";
import styles from "./NearbyList.module.scss";
import { DETAILS_KEY } from "../../utils/RouterConstants";
import { setToSearch } from "../../utils/RouterUtils";
interface Props {
    list: OrganisationInfoAppListViewModel[] | undefined;
    className?: string;
}
function NearbyList({ list, className }: Props) {
    const location = useLocation();
    return (
        <div className={classNames(styles.container, className)}>
            {hasValues(list) && (
                <div className={styles.listContainer}>
                    {list.map((organisation) => {
                        return (
                            <Link
                                key={`Card-${organisation.id}`}
                                className={styles.item}
                                to={{
                                    pathname: location.pathname,
                                    search: setToSearch(
                                        [{ key: DETAILS_KEY, value: organisation.id }],
                                        location.search,
                                    ),
                                }}
                                data-testid="OrganisationListLink"
                            >
                                <NearbyListCard organisation={organisation} />
                            </Link>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default NearbyList;
