import React, { useCallback, useEffect, useMemo } from "react";
import HappeningList from "./HappeningList";
import { GeoViewport } from "../../utils/MapUtils";
import { useGetHappeningClusterListCallback } from "../../hooks/useHappeningsApiHooks";
import { useClusterId, useFilters, useGroupsFilter } from "../../hooks/useRouterHooks";
import { RequestState } from "../../types/RequestState";

interface Props {
    viewport: GeoViewport;
}

const focusFilters = () => {
    document.getElementById("date-picker")?.focus();
};

export default function HappeningListClusterContainer({ viewport }: Props) {
    const paramClusterId = useClusterId();
    const [filters] = useFilters();
    const groups = useGroupsFilter();
    const [{ value, state }, getHappenings] = useGetHappeningClusterListCallback(viewport, paramClusterId);
    const fetch = useCallback(() => getHappenings({ ...filters, groups }), [filters, getHappenings, groups]);

    useEffect(() => {
        fetch();
    }, [fetch]);

    const isLoading = useMemo(() => state === RequestState.LOADING, [state]);

    return (
        <>
            <HappeningList list={value?.items} focusFilters={focusFilters} loading={isLoading} showOnMap />
        </>
    );
}
