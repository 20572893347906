import React, { useCallback, useState } from "react";
import NearbyMapContainer from "../../components/PerformanceMap/NearbyMapContainer";
import { useIsIframe } from "../../hooks/useRouterHooks";
import OrganisationDetailModalContainer from "../../components/OrganisationDetailModal/OrganisationDetailModalContainer";
import OrganisationListFilterContainer from "../../components/OrganisationListFilter/OrganisationListFilterContainer";
import { useShowFilters } from "../../hooks/useRouterHooks";
import styles from "./NearByOverviewPage.module.scss";
import classNames from "classnames";
type Props = {
    className?: string;
};
function NearByOverviewPage({}: Props) {
    const showFilters = useShowFilters();
    const onModalResize = useCallback((height: number) => {
        // Picked 125 because it worked on all resizings.
        setModalHeight(height + 125);
    }, []);
    const isIframe = useIsIframe();
    const [, setModalHeight] = useState(0);

    return (
        <>
            <div className={classNames(styles.container)}>
                {showFilters && <OrganisationListFilterContainer className={styles.filters} />}
            </div>
            <NearbyMapContainer />
            <OrganisationDetailModalContainer fillPage={!isIframe} onResize={onModalResize} />
        </>
    );
}
NearByOverviewPage.defaultProps = {
    className: "",
};

export default NearByOverviewPage;
